import React, { useState, useEffect } from "react";
import styled from "styled-components";
import agreements from "../util/agreements";
import applications from "../util/application";
import esign from "../util/esign";
import users from "../util/user";
import {
  MessageBar,
  Check,
  Header,
  Row,
  Text,
  DocWrapper,
  Button,
  Page,
  Modal,
} from "../components";
import scrollTo from "animated-scroll-to";
import queryString from "query-string";

const Actions = styled(Row)`
  button {
    margin-left: 16px;
  }
`;

const Marker = styled.div`
  width: 60px;
  height: 40px;
  position: absolute;
  transition-duration: 0.5s;
  top: ${(props) => (props.position ? props.position : "0")}
  left: -34px;
  background-size: cover !important;
  background: url('/icon-marker.svg');
  z-index: 2;
  display: flex;
  padding: 5px 0 0 18px;
  align-items: center;
  color: white;
  font-weight: 700;
`;

const FindNext = styled(Button)`
  @media (max-width: 768px) {
    visibility: hidden;
  }
  position: fixed;
  top: 110px;
  left: 50px;
  z-index: 9;
`;

const CompleteCheck = styled(Check)`
  width: 30px;
`;

const WorkArea = styled.div`
  position: relative;
  margin: 10px auto;
  max-width: ${({ size }) =>
    size && size.width ? size.width * 72 + "px" : "816px"};
  width: 100%;
`;

const MobileButtons = styled.div`
  @media (min-width: 768px) {
    visibility: hidden;
  }
  position: fixed;
  top: 110px;
  left: 50px;
  z-index: 9;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em;
  * {
    margin-right: 1em;
  }
`;

const Signer = (props) => {
  const [agreement, setAgreement] = useState();
  const [update, setUpdate] = useState(false);
  const [prefills, setPrefills] = useState();
  const [communitySigner, setCommunitySigner] = useState();
  const [requiredCount, setRequiredCount] = useState();
  const [markerPosition, setMarkerPosition] = useState();
  const [nextAgreement, setNextAgreement] = useState();
  const [
    showRemainingRequiredModal,
    setShowRemainingRequiredModal,
  ] = useState();
  const [ipaddress, setipaddress] = useState();
  const [message, setMessage] = useState();
  const [changed, change] = useState(false);
  const [docType, setDocType] = useState();

  async function saveAndExit() {
    if (!agreement.auditTrail) agreement.auditTrail = { history: [] };
    if (!agreement.auditTrail.history) agreement.auditTrail.history = [];
    agreement.auditTrail.history.push({
      action: "Fields updated and saved",
      timestamp: new Date(),
      ipAddress: ipaddress,
    });
    await agreements.update(agreement);
    await updatePrefills();
    if (docType === "physician") {
      props.history.push(
        `/${props.match.params.source}/${props.match.params.application}?tab=physician`
      );
    } else {
      props.history.push(
        `/${props.match.params.source}/${props.match.params.application}?tab=agreements`
      );
    }
  }

  async function save() {
    await agreements.update(agreement);
    await updatePrefills();
  }

  async function finish() {
    if (requiredCount > 0) {
      alert("Fill out all required fields");
    } else {
      if (!agreement.auditTrail) agreement.auditTrail = { history: [] };
      if (!agreement.auditTrail.history) agreement.auditTrail.history = [];
      agreement.auditTrail.history.push({
        action: "Finished and locked",
        timestamp: new Date(),
        ipAddress: ipaddress,
      });
      await agreements.update(agreement);
      await updatePrefills();

      const latestApplication = await applications.get(
        props.match.params.application
      );

      if (docType === "physician") {
        props.history.push(
          `/${props.match.params.source}/${props.match.params.application}?tab=physician`
        );
      } else {
        const currentAgreement = latestApplication.data.agreements.resident.filter(
          (item) => item.agreement_id === props.match.params.id
        );

        if (props.match.params.filler === "All") {
          for (const i in currentAgreement[0].signatures) {
            currentAgreement[0].signatures[i].signed = true;
          }
        }
        if (props.match.params.filler === "Community")
          currentAgreement[0].signatures.Community.signed = true;
        if (props.match.params.filler === "Resident")
          currentAgreement[0].signatures.Resident.signed = true;
        if (props.match.params.filler === "Manager")
          currentAgreement[0].signatures.Manager.signed = true;

        for (const i in latestApplication.data.agreements.resident) {
          if (
            latestApplication.data.agreements.resident[i].agreement_id ===
            props.match.params.id
          )
            latestApplication.data.agreements.resident[i] = currentAgreement[0];
        }
        latestApplication.data.progress.current = "In Progress";

        await applications.update(latestApplication.data);
        props.history.push(
          `/${props.match.params.source}/${props.match.params.application}?tab=agreements`
        );
      }
    }
  }

  async function updatePrefills() {
    const _prefills = agreement.pages.pages
      .map((_page) => {
        return _page.fields;
      })
      .flat()
      .filter(
        (_field) =>
          _field.prefill &&
          _field.prefill !== "null" &&
          _field.prefill !== "undefined"
      );
    await esign.addPrefills(_prefills, props.match.params.application);
  }

  async function next() {
    if (requiredCount > 0) {
      if (!agreement.auditTrail) agreement.auditTrail = {};
      await agreements.update(agreement);
      setShowRemainingRequiredModal(true);
    } else {
      const latestApplication = await applications.get(
        props.match.params.application
      );
      const currentAgreement = latestApplication.data.agreements.resident.filter(
        (item) => item.agreement_id === props.match.params.id
      );
      if (props.match.params.filler === "All") {
        for (const i in currentAgreement[0].signatures) {
          currentAgreement[0].signatures[i].signed = true;
        }
      }
      if (props.match.params.filler === "Community")
        currentAgreement[0].signatures.Community.signed = true;
      if (props.match.params.filler === "Resident")
        currentAgreement[0].signatures.Resident.signed = true;
      if (props.match.params.filler === "Manger")
        currentAgreement[0].signatures.Manager.signed = true;

      for (const i in latestApplication.data.agreements.resident) {
        if (
          latestApplication.data.agreements.resident[i].agreement_id ===
          props.match.params.id
        )
          latestApplication.data.agreements.resident[i] = currentAgreement[0];
      }

      await applications.update(latestApplication.data);
      await updatePrefills();

      window.location.href =
        "/sign/" +
        nextAgreement +
        "/" +
        props.match.params.application +
        "/" +
        props.match.params.filler +
        "/" +
        props.match.params.source;
    }
  }

  function updateAgreement(page, pageNumber) {
    agreement.pages.pages[pageNumber] = page;
    setAgreement(agreement);
    setMarker();
    if (requiredCount > 0) {
      setMessage(undefined);
    }
    if (requiredCount === 0 && message !== "All Required Fields Complete") {
      setMessage("All Required Fields Complete");
      change(!changed);
    }
  }

  function checkGroups(_field) {
    let complete = true;
    if (
      (_field.groupname &&
        _field.grouprequirements &&
        _field.filler === props.match.params.filler) ||
      props.match.params.filler === "All"
    ) {
      let _fields = [];
      agreement.pages.pages.filter((newPage) => {
        const _groupFields = newPage.fields.filter(
          (newfield) => newfield.groupname === _field.groupname
        );
        _fields = [..._groupFields, ..._fields];
      });

      if (_field.grouprequirements === "Only_One") {
        const count = _fields.filter((check) => check.value);
        if (count.length === 0) complete = false;
      }

      if (_field.grouprequirements === "At_least_one") {
        const count = _fields.filter((check) => check.value);
        if (count.length < 1) complete = false;
      }

      if (_field.grouprequirements === "More_than_one") {
        const count = _fields.filter((check) => check.value);
        if (count.length < 2) complete = false;
      }

      if (_field.groupname === "null" && _field.grouprequirements === "null") {
        const count = _fields.filter((check) => check.value);
        if (count.length === 0) complete = false;
      }
    }
    return complete;
  }

  function setMarker(scroll) {
    let count = 0;
    let firstMarker = false;
    let groups = [];

    for (const i in agreement.pages.pages) {
      for (const j in agreement.pages.pages[i].fields) {
        const field = agreement.pages.pages[i].fields[j];
        /* if (!checkGroups(field)) {
          if (
            !(groups.indexOf(field.groupname) !== -1) &&
            field.type !== "checkbox"
          ) {
            groups.push(field.groupname);
            count++;
            if (!firstMarker) {
              firstMarker = true;
              findPosition(field.fieldid, scroll);
            }
          }
        } else */ if (
          field.isrequired &&
          field.isrequired === "true" &&
          !field.value &&
          (field.filler === props.match.params.filler ||
            props.match.params.filler === "All") &&
          (field.prefill &&
          field.type !== "signature" &&
          field.type !== "initials"
            ? prefills && !prefills[field.prefill]
            : true)
        ) {
          count++;
          if (!firstMarker) {
            firstMarker = true;
            findPosition(field.fieldid, scroll);
          }
        }
      }
      setRequiredCount(count);
    }
  }

  function findPosition(id, scroll) {
    const field = document.getElementById(id);
    setMarkerPosition(getCoords(field).top - 130 + "px");
    if (scroll) {
      scrollTo(getCoords(field).top - 130 - 300, {
        cancelOnUserAction: true,
        speed: 1500,
      });
    }
  }

  console.log(agreement);

  function getCoords(elem) {
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  function validateCheckboxs(fieldId, groupname) {
    const latest = { ...agreement };
    for (const i in latest.pages.pages) {
      for (const j in latest.pages.pages[i].fields) {
        if (latest.pages.pages[i].fields[j].type === "checkbox") {
          if (
            latest.pages.pages[i].fields[j].groupname === groupname &&
            latest.pages.pages[i].fields[j].fieldid !== fieldId
          ) {
            latest.pages.pages[i].fields[j].value = false;
          }
          if (latest.pages.pages[i].fields[j].fieldid === fieldId) {
            latest.pages.pages[i].fields[j].value = true;
          }
        }
      }
    }
    setAgreement(latest);
    setUpdate(!update);
  }

  function getNextAgreement(application) {
    let unFinishedAgreements;
    if (props.match.params.filler === "All")
      unFinishedAgreements = application.agreements.resident.filter(
        (item) => !applications.allSignaturesComplete(item.signatures)
      );
    if (props.match.params.filler === "Community")
      unFinishedAgreements = application.agreements.resident.filter((item) =>
        applications.communityTurn(item.signatures)
      );
    if (props.match.params.filler === "Resident")
      unFinishedAgreements = application.agreements.resident.filter((item) =>
        applications.residentTurn(item.signatures)
      );
    for (const i in unFinishedAgreements) {
      if (unFinishedAgreements[i].agreement_id === props.match.params.id) {
        if (
          unFinishedAgreements.length > i &&
          unFinishedAgreements[parseInt(i) + 1]
        )
          setNextAgreement(unFinishedAgreements[parseInt(i) + 1].agreement_id);
      }
    }
  }

  async function updateAuditTrail(object) {
    if (!agreement.auditTrail) agreement.auditTrail = { history: [] };
    if (!agreement.auditTrail.history) agreement.auditTrail.history = [];
    agreement.auditTrail.history.push(object);
    await agreements.update(agreement);
  }

  function assignAgreementAndPrefills(_prefills, _agreement) {
    _agreement.pages.pages = _agreement.pages.pages.filter((page) =>
      page.fields.filter((field) => {
        if (field.prefill in _prefills) {
          if (
            !field.value &&
            field.type !== "signature" &&
            field.type !== "initials"
          ) {
            field.value = _prefills[field.prefill];
          }
        }
      })
    );

    //Set tabindex
    let tabIndex = 1;
    _agreement.pages.pages.forEach((_page) => {
      _page.fields.sort((a, b) => {
        return a.position.y - b.position.y;
      });
      _page.fields.forEach((_field) => {
        _field.tabIndex = tabIndex;
        tabIndex++;
      });
    });

    setAgreement(_agreement);
  }

  function fillPrefills(prefill, value) {
    const _prefill = {
      [prefill]: value,
    };
    const _updated = { ...prefills, ..._prefill };
    setPrefills(_updated);
  }

  useEffect(() => {
    async function init() {
      try {
        const parsedSearch = queryString.parse(props.location.search);
        if (parsedSearch.type) {
          setDocType(parsedSearch.type);
        }

        const me = await users.get();
        const _ip = await users.getIpAddress();
        const agreementLatest = await agreements.get(props.match.params.id);
        const application = await applications.get(
          props.match.params.application
        );
        let _prefills = await esign.parsePrefills(application.data.myInfo);
        _prefills.room_num = _prefills.unit_number;
        _prefills.move_in_date = _prefills.due_date;

        setipaddress(_ip.data.ipaddress);
        getNextAgreement(application.data);
        setCommunitySigner(me.data);
        setPrefills(_prefills);
        assignAgreementAndPrefills(_prefills, agreementLatest.data);
      } catch (error) {
        console.log(error);
        props.history.push("/login");
      }
    }

    init();
  }, [props.match.params.id]);

  useEffect(() => {
    if (agreement) setMarker();
  }, [agreement]);

  const [localInitials, setLocalInitials] = useState(null);

  return (
    <main style={{ overflowX: "scroll" }}>
      <Header>
        <Actions justify="flex-end">
          <Button secondary onClick={saveAndExit}>
            Save & Exit
          </Button>
          {nextAgreement && <Button onClick={next}>Finish</Button>}
          {!nextAgreement && requiredCount === 0 && (
            <Button onClick={finish}>Finish</Button>
          )}
          {!nextAgreement && requiredCount > 0 && (
            <Button disabled>Finish</Button>
          )}
        </Actions>
      </Header>

      {agreement && (
        <div>
          <ButtonGroup>
            {requiredCount !== 0 ? (
              <FindNext secondary onClick={setMarker}>
                Next
              </FindNext>
            ) : (
              <CompleteCheck checked />
            )}

            <MobileButtons>
              <Button secondary onClick={setMarker}>
                Next
              </Button>
              <Button secondary onClick={saveAndExit}>
                Save & Exit
              </Button>
              {nextAgreement && <Button onClick={next}>Finish</Button>}
              {!nextAgreement && requiredCount === 0 && (
                <Button onClick={finish}>Finish</Button>
              )}
              {!nextAgreement && requiredCount > 0 && (
                <Button disabled>Finish</Button>
              )}
            </MobileButtons>
          </ButtonGroup>
          <DocWrapper>
            <div id="esign-doc" className="esign-doc-wrapper">
              <WorkArea size={agreement.details.size}>
                {requiredCount && requiredCount > 0 ? (
                  <Marker position={markerPosition}>{requiredCount}</Marker>
                ) : null}

                {agreement.pages &&
                  agreement.pages.pages.map((item, index) => {
                    return (
                      <Page
                        key={index}
                        pageNumber={index}
                        listen={update}
                        page={item}
                        save={save}
                        size={agreement.details.size}
                        prefills={prefills}
                        fillPrefills={fillPrefills}
                        updateAgreement={updateAgreement}
                        validateCheckboxs={validateCheckboxs}
                        communitySigner={communitySigner}
                        updateAuditTrail={updateAuditTrail}
                        ipaddress={ipaddress}
                        localInitials={localInitials}
                        setLocalInitials={setLocalInitials}
                        {...props}
                      />
                    );
                  })}
              </WorkArea>
            </div>
          </DocWrapper>

          <Modal
            title="Agreement Incomplete"
            show={showRemainingRequiredModal}
            close={() => setShowRemainingRequiredModal(false)}
          >
            <Row padding="24px" justify="center">
              <Text kind="p" color="#4D5864" center>
                There are still required fields to fill out, are you sure you
                want to go to the next agreement?
              </Text>
            </Row>
            <Actions
              padding="0 24px 24px"
              alignItems="center"
              justify="flex-end"
            >
              <Button
                secondary
                onClick={() => setShowRemainingRequiredModal(false)}
              >
                Cancel
              </Button>
              <a
                href={`/sign/${nextAgreement}/${props.match.params.application}/${props.match.params.filler}/${props.match.params.source}`}
              >
                <Button>Save & Next</Button>
              </a>
            </Actions>
          </Modal>

          <MessageBar message={message} listen={changed} />
        </div>
      )}
    </main>
  );
};

export default Signer;
